const usersData = [  
  {concept:'Paseos grupales' , hrs_min: '', hrs_max: '', cost1: '', cost2: '', cost3: '', cost4: '', expiration: '', _rowVariant: 'info'},
  {concept:'Esporádico', hrs_min: '1', hrs_max: '11', cost1: '9.900',  cost2: '14.900', cost3: '20.000', cost4: '24.300', expiration: '60 días'},
  {concept:'Frecuente', hrs_min: '12', hrs_max: '23', cost1: '9.700',  cost2: '13.900', cost3: '18.500', cost4: '22.300', expiration: '60'},
  {concept:'Permanente', hrs_min: '24', hrs_max: '-', cost1: '9.500', cost2: '12.900',  cost3: '17.000', cost4: '20.300', expiration: '120'},

  {concept:'Paseos dedicado', hrs_min: '', hrs_max: '', cost1: '', cost2: '', cost3: '', cost4: '',expiration: '', _rowVariant: 'info'},
  {concept:'Esporádico', hrs_min: '1', hrs_max: '11', cost1: '14.500', cost2: '17.500', cost3: '20.500', cost4: '', expiration: '60'},
  {concept:'Frecuente', hrs_min: '12', hrs_max: '60', cost1: '13.500', cost2: '16.500', cost3: '19.500', cost4: '', expiration: '60'},

  {concept:'Adiestramiento', hrs_min: '', hrs_max: '', cost1: '', cost2: '', cost3: '', cost4: '', expiration: '', _rowVariant: 'info'},
  {concept:'Esporádico', hrs_min: '1', hrs_max: '11', cost1: '23.000', cost2: '', cost3: '', cost4: '', expiration: '60'},
  {concept:'Frecuente', hrs_min: '12', hrs_max: '60', cost1: '20.500', cost2: '', cost3: '', cost4: '', expiration: '60'},
  
  {concept:'Tarifas adicionales', hrs_min: 'VALOR', hrs_max: '', cost1: '', cost2: '', cost3: '', cost4: '', expiration: '', _rowVariant: 'info'},  
  {concept:'T. De recaudo', hrs_min: '500', hrs_max: '', cost1: '', cost2: '', cost3: '', cost4: '', expiration: ''},
  {concept:'T. De desplazamiento', hrs_min: '1.600', hrs_max: '', cost1: '', cost2: '', cost3: '', cost4: '', expiration: ''},
  {concept:'T. De Exclusividad de paseador', hrs_min: '2.200', hrs_max: '', cost1: '', cost2: '', cost3: '', cost4: '', expiration: ''},
  {concept:'T. De cancelación', hrs_min: '3.600', hrs_max: '', cost1: '', cost2: '', cost3: '', cost4: '', expiration: ''},
 
  ];

const hotelData = [  
  {concept:'Día 1', mascota_1: '49.000', mascota_2: '76.000', mascota_3: '97.000',  mascota_4: '113.000'},
  {concept:'Día 2', mascota_1: '49.000', mascota_2: '76.000', mascota_3: '97.000',  mascota_4: '113.000'},
  {concept:'Día 3', mascota_1: '49.000', mascota_2: '76.000', mascota_3: '97.000',  mascota_4: '113.000'},

  {concept:'Día 4', mascota_1: '44.000', mascota_2: '66.000', mascota_3: '82.000',  mascota_4: '98.000'},
  {concept:'Día 5', mascota_1: '44.000', mascota_2: '66.000', mascota_3: '82.000',  mascota_4: '98.000'},
  {concept:'Día 6', mascota_1: '44.000', mascota_2: '66.000', mascota_3: '82.000',  mascota_4: '98.000'},

  {concept:'Día 7',  mascota_1: '39.500', mascota_2: '61.500', mascota_3: '77.500',  mascota_4: '93.500'},
  {concept:'Día 8',  mascota_1: '39.500', mascota_2: '61.500', mascota_3: '77.500',  mascota_4: '93.500'},
  {concept:'Día 9',  mascota_1: '39.500', mascota_2: '61.500', mascota_3: '77.500',  mascota_4: '93.500'},
  {concept:'Día 10', mascota_1: '39.500', mascota_2: '61.500', mascota_3: '77.500',  mascota_4: '93.500'},
  {concept:'Día 11', mascota_1: '39.500', mascota_2: '61.500', mascota_3: '77.500',  mascota_4: '93.500'},
  {concept:'Día 12', mascota_1: '39.500', mascota_2: '61.500', mascota_3: '77.500',  mascota_4: '93.500'},

  {concept:'Día 13', mascota_1: '36.000', mascota_2: '58.000', mascota_3: '74.000',  mascota_4: '90.000'},
  {concept:'Día 14', mascota_1: '36.000', mascota_2: '58.000', mascota_3: '74.000',  mascota_4: '90.000'},
  {concept:'Día 15', mascota_1: '36.000', mascota_2: '58.000', mascota_3: '74.000',  mascota_4: '90.000'},
  
  {concept:'Día adicional', mascota_1: '36.000', mascota_2: '58.000', mascota_3: '74.000',  mascota_4: '90.000'},
 
  ];
  const walkerFees = [  
    {concept:'Paseos',  cost1: '', cost2: '', cost3: '', cost4: '', extrafee:'',  _rowVariant: 'info'},    
    {concept:'Paseo con próposito grupal', cost1: '6.500', cost2: '8.000', cost3: '9.500', cost4: '11.000', extrafee:'1.500'},
            
    {concept:'Paseo dedicado esporádico', cost1: '8.100', cost2: '9.800', cost3: '11.500', cost4: '', extrafee:'1700', },
    {concept:'Paseo dedicado frecuente',  cost1: '7.600', cost2: '9.300', cost3: '11.000', cost4: '', extrafee:'1700', },

    {concept:'Entrenamiento', cost1: '', cost2: '', cost3: '', cost4: '', extrafee:'',  _rowVariant: 'info'},   
    {concept:'Sesión entrenamiento esporádico', cost1: '12.600', cost2: '', cost3: '', cost4: '', extrafee:''},
    {concept:'Sesión entrenamiento frecuente',  cost1: '11.600', cost2: '', cost3: '', cost4: '', extrafee:''},
    
    {concept:'Adicionales por servicio', cost1: 'VALOR', cost2: '', cost3: '', cost4: '', extrafee:''},  
    {concept:'Tarifa de exclusividad de Paseador', cost1: '2.200', cost2: '', cost3: '', cost4: '', extrafee:''},
    {concept:'Tarifa de cancelación', cost1: '1.500', cost2: '', cost3: '', cost4: '', extrafee:''},          
  ];

  const rescheduleQuantity = [
    { package:'Menor a 12 servicios', quantity: 0},
    { package:'De 12 a 23 servicios', quantity: 1},
    { package:'De 24 a más servicios', quantity: 2},
  ]
  const rescheduleRainQuantity = [
    { package:'Menor o igual a 6 servicios', quantity: 0},
    { package:'De 7 a 11 servicios', quantity: 1},
    { package:'De 12 a 23 servicios', quantity: 2},
    { package:'24 o más servicios', quantity: 4},
  ]

  const walkingFeats = [
    {concept: 'Tiempo de paseo', description: '45 minutos.'},
    {concept: 'Recorrido', description: 'Un desplazamiento máximo de 4 km y mínimo 1.2 km, podrán existir recorridos menores según la edad y el estado físico de la mascota o el clima.'},
    {concept: 'Incluye', description: 'Hidratación, bolsas ecológicas para necesidades fisiológicas de tu mascota.'},
    {concept: 'Rastreo', description: 'Al iniciar el paseo de tu mascota podrás visualizar en tu móvil u ordenador su ubicación mediante sistema GPS con periocidad de actualización de 3 minutos.'},

    {concept: 'Conteo de distancia', description: 'Durante y al final del recorrido podrás conocer la distancia recorrida por tu mascota en kilómetros.'},
    {concept: 'Notificaciones', description: 'Recibirás notificaciones de inicio y de fin del paseo.'},
    {concept: 'Perfil', description: 'Se te brindará la información del Proveedor del servicio, nombre, ocupación, etc.'},
    {concept: 'Otros', description: 'El CLIENTE podrá hacer uso de la PLATAFORMA para realizar seguimiento de pagos, programaciones y ejecuciones.'},
    {concept: 'Nota', description: 'El sistema  GPS y el conteo de distancia son ayudas técnicas que puede presentar fallas en cualquier momento, usted como cliente entiende y acepta dicha situación, la no disposición de información de estos o la información errónea no implican incumplimiento del servicio(ejecución del paseo o adiestramiento).'},
    
  ]
  const trainingFeats = [
    {concept: 'Tiempo', description: '1 hora.'},
    {concept: 'Descripción', description: 'Las sesiones de adiestramiento comprenden inicialmente un plan básico que estipula los siguientes propósitos:'},
    {concept: 'Comandos nivel básico', description: 
    'Prestar atención, '+ 
    'sentarse, '+
    'acostarse, ' +
    'quedarse quieto,' +
    'dar la pata, '+ 
    'parado, ' +
    'arrastrarse, '+
    'caminar junto al amo sin halar del collar.' +
    'Venir y sentarse junto' 
    },
    {concept: 'Comandos nivel intermedio', description: 
      'Acudir al llamado (venir), '+ 
      'Quieto, ' +
      'Trae / busca, ' +
      'prestar atención, ' +
      'tocar un objeto, ' +
      'a tu lugar, ' +
      'salta, ' +
      'caminar junto al amo en casa, '+
      'permanecer en casa, ' +
      'esperar para salir de casa'
    },
    {concept: 'Otros', description: 'El CLIENTE podrá hacer uso de la PLATAFORMA para realizar seguimiento de pagos, programaciones y ejecuciones.'},
    
  ]
  const kindergartenFeats = [
    {concept: 'Tiempo', description: '24 horas.'},
    {concept: 'Descripción', description: 'El servicio de guardería considera el alojamiento de una mascota en la residencia de un Proveedor Go Guau, persona con experiencia previa en el trato de mascotas (paseador), quien ha verificado también su entorno familiar como PET Friendly y dispone del espacio físico mínimo para el alojamiento.'},
    {concept: 'Servicio básico', description: 
    'Asistencia mínima 3 veces al día (mañana, tarde, noche, '+ 
    '2 salidas: mañana y noche para necesidades fisiológicas, ' +
    'hidratación, '+
    'dosificación de alimento (El alimento debe ser suministrado por el cliente), ' + 
    'bolsas ecológicas para necesidades fisiológicas de tu mascota'   
    },
    {concept: 'Servicio Premium', description: 
    'Comedor electrónico con cámara de video, micrófono, '+ 
    'dosificador electrónico de alimento (manipulable por aplicación móvil) el servicio premium tiene un valor adicional, ' +
    'atención telefónica al cliente para seguimiento, obligatorias 2 por parte del Go Anfitrión, adicionales a discreción del proveedor, '+
    'dosificación de alimento (El alimento debe ser suministrado por el cliente), ' + 
    'bolsas ecológicas para necesidades fisiológicas de tu mascota'   
    },
    {concept: 'Notificaciones', description: 
      'Recibirás notificaciones de inicio y de fin del servicio, '
    },
    {concept: 'Perfil', description: 'Se te brindará la información del Proveedor del servicio, nombre, ocupación, etc.'},
    {concept: 'Otros', description: 'El CLIENTE podrá hacer uso de la PLATAFORMA para realizar seguimiento de pagos, programaciones y ejecuciones.'},
  ]
  
  // export default usersData

  module.exports = {
    usersData,
    rescheduleQuantity,
    rescheduleRainQuantity,
    walkingFeats,
    trainingFeats,
    walkerFees,
    kindergartenFeats,
    hotelData
  };
  