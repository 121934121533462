<template>
<div class="container">
	<h1 class="title">Términos y Condiciones</h1>

    <p class="paragraph">
        GO GUAU <br>
        !Tu mejor amigo en manos amigas¡
    </p>
    <p class="paragraph">
        Última actualización: 30 de Julio de 2024
    </p>
   
    <div>
        <p class="subtitle">I. RELACIÓN CONTRACTUAL </p>
        <p class="paragraph">
           Las presentes Condiciones (“Condiciones”) regulan el acceso o uso que usted haga, 
           como persona (incluso sin una cuenta), desde cualquier país del mundo de las 
           aplicaciones tecnológicas, páginas web, contenido, dependencias, medios de comunicación, 
           productos y servicios puestos a su disposición por la empresa GO GUAU, los cuales 
           llamaremos de ahora en adelante y en general “LA PLATAFORMA”; GO GUAU es una sociedad 
           de responsabilidad limitada constituida en Popayán Cauca,  en la cámara de comercio 
           de la misma ciudad.
        </p>
        <p class="paragraph">
            LEA ESTAS CONDICIONES DETENIDAMENTE ANTES DE ACCEDER, USAR O SOLICITAR LOS SERVICIOS DE GO GUAU.
        </p>            
        <p class="paragraph">
            Mediante su acceso, uso o solicitud de servicios mediante la plataforma de GO GUAU, 
            usted acuerda vincularse jurídicamente por estas Condiciones, que establecen una relación 
            contractual entre usted y GO GUAU. Si usted no acepta estas Condiciones, no podrá acceder 
            o usar los Servicios u obtener los productos. Estas Condiciones sustituyen expresamente 
            los acuerdos o compromisos previos que se hayan tenido con usted. GO GUAU podrá poner 
            fin de inmediato a estas Condiciones o cualquiera de los Servicios o productos brindados 
            a usted o, en general, dejar de ofrecer o denegar el acceso a LA PLATAFORMA o cualquier 
            parte de ella, en cualquier momento y por cualquier motivo a su criterio.
        </p>
    </div>

    <div>
        <p class="subtitle">II. TERMINOS Y CONDICIONES PRINCIPALES </p>
        <p class="subtitle2">1. ALCANCE DEL SERVICIO</p>
        <p class="paragraph">
            El servicio ofrecido por GO GUAU se constituye en el uso de su 
            PLATAFORMA por parte de usuarios que requieren servicios para 
            mascotas que acordamos llamar CLIENTES y usuarios terceros prestadores 
            de dichos servicios que denominaremos PROVEEDORES. GO GUAU tiene por 
            propósito sin obligarse a ello, facilitar la intermediación entre 
            CLIENTES Y PROVEEDORES, por ello podrá denominarse también el INTERMEDIARIO. 
            El uso de la plataforma de GO GUAU permite organizar y planear los servicios 
            de paseos y adiestramiento con terceros particulares independientes, así como 
            la obtención de productos.
        </p>
        
        <p class="subtitle2">2. TIPOS DE SERVICIOS</p>
        <p class="paragraph">2.1. Servicios suministrados por terceros (proveedores)</p>
        <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Servicio de paseo de mascotas</span>
        </p> 

        <CRow class="paragraph">
            <CCol col="12" xl="10">
            <CCard>                
                <CCardBody>
                    <b-table
                        striped
                        :items="itemsWalkingFeats"
                        :fields="fieldsWalkingFeats"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        stacked="md"
                        show-empty
                        small
                        @filtered="onFiltered"      
                        >
                        <template #cell(name)="row">
                            {{ row.value }}                        
                        </template>                                             
                    </b-table>
                </CCardBody>
            </CCard>
            </CCol>
        </CRow>

        <p class="paragraph padded">
            El servicio de paseo de mascotas con propósito “Grupal” como “dedicado” no tiene un paseador (PROVEEDOR) 
            exclusivo, este podrá variar según la disponibilidad de los mismos, en caso de requerir exclusividad del 
            PROVEEDOR, se incluirá una tarifa adicional, la exclusividad solo está disponible para paquetes de compra 
            “frecuente” y “permanente”, bajo esta modalidad el CLIENTE acepta que el proveedor realice cambios a la 
            programación estipulada según su propio cronograma, dichos cambios serán en mutuo acuerdo con el CLIENTE 
            y usted reconoce que de darse un incumplimiento o un cambio de horario será única y exclusivamente 
            responsabilidad del PROVEEDOR.
        </p>

        <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Servicio de adiestramiento</span>
        </p> 

        <CRow class="paragraph">
            <CCol col="12" xl="10">
            <CCard>                
                <CCardBody>
                    <b-table
                        striped
                        :items="itemsTrainingFeats"
                        :fields="fieldsTrainingFeats"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        stacked="md"
                        show-empty
                        small
                        @filtered="onFiltered"      
                        >
                        <template #cell(name)="row">
                            {{ row.value }}                        
                        </template>                                             
                    </b-table>
                </CCardBody>
            </CCard>
            </CCol>
        </CRow>

         <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Servicio de guardería</span>
        </p> 

        <CRow class="paragraph">
            <CCol col="12" xl="10">
            <CCard>                
                <CCardBody>
                    <b-table
                        striped
                        :items="itemsKindergartenFeats"
                        :fields="fieldsTrainingFeats"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        stacked="md"
                        show-empty
                        small
                        @filtered="onFiltered"      
                        >
                        <template #cell(name)="row">
                            {{ row.value }}                        
                        </template>                                             
                    </b-table>
                </CCardBody>
            </CCard>
            </CCol>
        </CRow>

        <p class="paragraph padded">
            El servicio de Guardería requiere que el amo de la mascota la entregue y recoja en 
            vivienda del prestador del servicio, para ello deberá haber realizado la programación 
            y el pago previo, es responsabilidad del cliente entregar el alimento suficiente para 
            la estadía de la mascota y la “cama de nuestro amigo Go Guau”, esto con el fin de 
            evitar enfermedades por uso de otros caninos.
        </p>

        <p class="paragraph">2.2. Seguro de servicio - ofertado por Go Guau (opcional):</p>

        <p class="paragraph">El cliente es consciente que durante la ejecución de los servicios por 
            parte del proveedor pueden llegar a presentarse situaciones adversas para las mascotas, 
            entre ellas: lesiones, mordeduras, u otras que requieran atención veterinaria. Con base 
            en lo anterior GO GUAU oferta y recomienda al cliente obtener un seguro de servicio, 
            que cubrirá gastos de atención veterinaria de urgencia (no tratamientos) hasta por 500 
            veces el valor asegurado, por hechos o situaciones dadas y diligenciadas únicamente en 
            el tiempo de ejecución de los servicios (no posteriores); el cliente autoriza con el pago, 
            la asistencia médica en caso de ser necesaria; para mascotas no aseguradas, el cliente 
            asumirá los gastos veterinarios que lleguen a requerirse. Go Guau como empresa de intermediación 
            no se responsabiliza por daños o lesiones u otros que resulten a causa del proveedor del servicio 
            ni por los que resulten de la atención veterinaria en caso de requerirse, entendiendo que es un 
            tercero ajeno a la marca.
        </p>

        <p class="subtitle2">3. TIEMPOS Y COSTOS</p>    

            <p class="paragraph">Las tarifas estipuladas por el PROVEEDOR para los servicios de paseo y adiestramiento son las siguientes:</p>
            <CRow>
                <CCol col="12" xl="12">
                <CCard>                
                    <CCardBody>
                    <CDataTable
                        hover
                        striped
                        :items="items"
                        :fields="fields"
                        :items-per-page="20"                    
                        :active-page="activePage"                    
                        :pagination="{ doubleArrows: false, align: 'center'}"                    
                    >
                        <template #concept="data">
                        <td>
                            <CBadge :color="getBadge(data.item.concept)">
                            {{data.item.concept}}
                            </CBadge>
                        </td>
                        </template>
                    </CDataTable>
                    </CCardBody>
                </CCard>
                </CCol>
            </CRow>

            <p class="paragraph"><strong>El cuadro de tarifas anterior es una referencia, las misma pueden variar de ciudad a ciudad, el cliente acepta informarse de las de los precios que le corresponden mediante el servicio de atención al cliente.</strong></p>
            <p class="paragraph"><strong>Notas</strong></p>

            <p class="paragraph padded">
                <span class="bullet">●</span><span class="margin">Un GO GUAU AMIGO hace referencia a una mascota.</span><br>
                <span class="bullet">●</span><span class="margin">Para paseos grupales con integrantes de diferentes domicilios, aplican descuentos a discreción del intermediador.</span><br>   
                <span class="bullet">●</span><span class="margin">En los adiestramientos, el CLIENTE debe suministrar los premios y/o recompensa (galletas, gomitas).</span><br>
                <span class="bullet">●</span><span class="margin">En los adiestramientos, el CLIENTE debe suministrar los premios y/o recompensa (galletas,
                    gomitas).</span><br>
                <span class="bullet">●</span><span class="margin">Para servicios de paseo que requieren exclusividad (un mismo paseador) se suma la tarifa respectiva de 2200 pesos a la 
                    tarifa estándar. La exclusividad se mantiene durante el periodo del paquete adquirido, no aplica a paquetes futuros. 
                    La exclusividad solo aplica para servicios frecuentes y permanentes (PAQUETES).
                </span><br>
                <span class="bullet">●</span><span class="margin">Los servicios descritos en la tabla anterior tienen un periodo de validez de 60 días calendario luego del pago para su ejecución, pasado este tiempo caducarán; excepto en los paseos Grupales de Tipo Permanente, los paseos dedicados frecuentes y los entrenamientos básicos frecuentes, donde se estipulan 120 días calendario de validez antes de caducar.
                </span>
            </p>

            <p class="paragraph">Las tarifas estipuladas por el PROVEEDOR para los servicios de Guardería son las siguientes:</p>
            <CRow>
                <CCol col="12" xl="12">
                <CCard>                
                    <CCardBody>
                    <CDataTable
                        hover
                        striped
                        :items="itemsHotel"
                        :fields="fieldsHotel"
                        :items-per-page="20"                    
                        :active-page="activePage"                    
                        :pagination="{ doubleArrows: false, align: 'center'}"                    
                    >
                        <template #concept="data">
                        <td>
                            <CBadge :color="getBadge(data.item.concept)">
                            {{data.item.concept}}
                            </CBadge>
                        </td>
                        </template>
                    </CDataTable>
                    </CCardBody>
                </CCard>
                </CCol>
            </CRow>

        <div>
            <p class="subtitle2">3. MEDIOS DE PAGO</p>
            <p class="paragraph">Los medios dispuestos para el pago de tu servicio GO GUAU son los siguientes:</p>
            <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
            Transferencia bancaria: Cta de ahorros Bancolombia No. {{bankAccount.number}} - Cédula: {{bankAccount.numDoc}}</span></p>
            <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
            Nequi : {{line}}</span></p>
            <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
            Daviplata : {{line}}</span></p>
            <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
            Efectivo: a quien designe GO GUAU previa autorización</span></p>
            <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
            El pago de los servicios será siempre anticipado</span></p>
            <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
            PSE</span></p>
        </div>

        <div>   
            <p class="subtitle2">5. TÉRMINOS DE ESPERA, CANCELACIONES Y DEVOLUCIÓN DE DINERO</p>
            <p class="paragraph padded">
                <span class="bullet">●</span><span class="margin"><strong>Esperas:</strong> </span> <br><br>
                <span>Al momento de las prestaciones del servicio existe la posibilidad de que el CLIENTE no atienda al llamado cuando el paseador (PROVEEDOR) realice presencia en su domicilio y se pueden dar las siguientes situaciones:</span> <br><br>

                <span><strong>a. Ninguna respuesta al llamado a puerta:</strong> en esta situación el paseador realizará 3 llamados a puerta y esperará un máximo de 20 minutos contados desde la hora en que debería iniciar el servicio, de no haber ninguna respuesta, el paseador se retirará del domicilio del cliente y el servicio se entenderá como ejecutado.</span> <br>
                <span><strong>b. Respuesta tardía al llamado a puerta:</strong>  en la situación en las que el cliente atienda el llamado del paseador para iniciar el servicio de forma tardía y durante el plazo máximo de 20 minutos, el pasador ejecutará el servicio Manteniendo la hora de finalización programada para no afectar la agenda de servicios posteriores.</span> <br><br>

                <span>Llegada tardía del paseador, en caso de que el paseador no llegue a la hora acordada para el servicio, el usuario podrá informar a la línea GO GUAU {{line}}, dicha situación, de esta forma se solicitará compensación del tiempo la cual podrá darse en la ejecución del mismo servicio o en servicios posteriores según lo permita el agendamiento.</span>
                    
            </p>

            <p class="paragraph padded">
                <span class="bullet">●</span><span class="margin"><strong>Cancelación de servicios programados por motivos diferentes a lluvia:</strong> </span> <br><br>
                <span>Respecto de los servicios programados, el cliente podrá realizar el siguiente número de
                    cancelaciones con reprogramación sin costo según el paquete de compra:</span> <br><br>
                <CRow class="paragraph">
                    <CCol col="12" xl="10">
                    <CCard>                
                        <CCardBody>
                            <b-table
                                striped
                                :items="itemsReschedule"
                                :fields="fieldsReschedule"
                                :current-page="currentPage"
                                :per-page="perPage"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :sort-direction="sortDirection"
                                stacked="md"
                                show-empty
                                small
                                @filtered="onFiltered"      
                                >
                                <template #cell(name)="row">
                                    {{ row.value }}                        
                                </template>                                             
                            </b-table>
                        </CCardBody>
                    </CCard>
                    </CCol>
                </CRow>                                 
            </p>

                <p class="paragraph padded">
                    Lo anterior, sin que sea necesario una justificación o motivo, siempre y cuando la solicitud de cancelación se informe debidamente con un tiempo superior a 24 horas anticipadas a la prestación del servicio y haya sido aprobada por GO GUAU en el mismo lapso de tiempo. Es responsabilidad del CLIENTE la solicitud de reprogramación, cuya ejecución solo podrá llevarse a cabo entre la fecha del paseo inicial y el tiempo de caducidad del servicio, estipuladas al momento del pago y quedará sujeta a disponibilidad de paseadores. Para dichas situaciones comunicarse vía whatsapp al número {{line}}.
                </p>

                <p class="paragraph padded">
                    Usted reconoce que la cancelación implica una gestión operativa y administrativa adicional entre los CLIENTES Y PROVEEDORES a cargo del INTERMEDIARIO, por ello, En caso de que el cliente realice cancelaciones durante las 24 horas anteriores a la prestación del servicio, con justificación o sin ella (Por motivos diferentes a lluvia), acepta pagar la tarifa de cancelación.<br><br>

                    <span>a. Para cancelaciones de 1 servicio de <strong>PASEO</strong> en <strong>tiempos menores a 30 minutos de su ejecución, usted como cliente acepta el pago de la tarifa de cancelación por 3300 pesos.</strong></span> <br>

                    <span>b. Para cancelaciones de 1 servicio de <strong>ADIESTRAMIENTO</strong> en <strong>tiempos menores a 30 minutos de su ejecución, usted como cliente acepta el pago de la tarifa de cancelación por 7000 pesos.</strong> </span> <br>

                    <span>c. Para cancelaciones de 1 servicio de <strong>GUARDERÍA</strong> en <strong>tiempos menores a 30 minutos de su ejecución, usted como cliente acepta el pago de la tarifa de cancelación por 12.000 pesos.</strong> </span> <br>

                    <span>d. En el caso de <strong>Cancelaciones de 2 o más servicios (incluidos Paquetes) Programados</strong> en cualquier momento, el cliente asume una tarifa de cancelación correspondiente al 37.5% del valor total pagado. </span> <br><br>
                </p>
            
            <p class="paragraph padded">
                <span class="bullet">●</span><span class="margin"><strong>Aplazamiento por lluvia.</strong> </span> <br><br>
                <span>
                    En Caso de lluvia inminente el proveedor podrá comunicarse con el cliente a fin de solicitar su autorización para aplazar el servicio, el cliente es el único que autoriza el aplazamiento y determina la nueva hora para prestación del mismo, el cual en medida de lo posible deberá realizarse el mismo día, no obstante en caso de no poderse ejecutar quedará como cancelado y se le reasignará una nueva fecha de ejecución. Si usted como cliente desea aplazar el servicio por lluvia, por favor comuníquese con la Go Guau Operadora o gestiónelo mediante el proveedor.
                </span>
            </p>

            <p class="paragraph padded">
                <span class="bullet">●</span><span class="margin"><strong>Cancelación por lluvia de servicios programados:</strong> </span> <br><br>
                <span>En caso de lluvia prevista el cliente podrá realizar la cancelación y reprogramación del servicio, siempre y cuando dicha solicitud se informe debidamente con más de 30 minutos de anticipación a la prestación del servicio y haya sido aprobada por GO GUAU en el mismo lapso de tiempo. El Nro. de cancelaciones con reprogramación por lluvia permitidas es el siguiente:</span>
                <br> <br>
                <CRow class="paragraph">
                    <CCol col="12" xl="10">
                    <CCard>                
                        <CCardBody>
                            <b-table
                                striped
                                :items="itemsRainReschedule"
                                :fields="fieldsReschedule"
                                :current-page="currentPage"
                                :per-page="perPage"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :sort-direction="sortDirection"
                                stacked="md"
                                show-empty
                                small
                                @filtered="onFiltered"      
                                >
                                <template #cell(name)="row">
                                    {{ row.value }}                        
                                </template>                                             
                            </b-table>
                        </CCardBody>
                    </CCard>
                    </CCol>
                </CRow>                  
                
                <span>Es responsabilidad del CLIENTE la solicitud de reprogramación, cuya ejecución solo podrá llevarse a cabo entre la fecha del paseo inicial y la fecha de caducidad del servicio, estipuladas al momento del pago del paquete y quedará sujeta a disponibilidad de paseadores, las reprogramaciones no son acumulativas de un paquete a otro.</span> <br><br>
                <span>Para cancelaciones de servicios  (MÁXIMO 1 POR VEZ con reprogramación) de <strong>PASEO, GUARDERÍA, ADIESTRAMIENTO, CONDUCTA U OTRO</strong> en <strong>tiempos menores a 30 minutos de su ejecución POR MOTIVO DE LLUVIA, usted como cliente acepta el pago de la tarifa de cancelación por 3500 pesos.</strong> La cancelación de servicios unitarios por lluvia sin reprogramación asumen tarifa de cancelación de 35% del valor total cobrado.</span> <br><br>
                <span>En el caso de que durante la ejecución del servicio EL CLIENTE decida suspender la ejecución del mismo, no habrá lugar a compensación del tiempo, no habrá cobro de tarifa de cancelación, pero el paseo se entenderá como ejecutado.</span> <br><br>
                <span>Considerando que la lluvia es un factor externo, de fuerza mayor y teniendo en cuenta también el tiempo cesante de los paseadores junto a las limitaciones de agenda, una vez consumidos los créditos de cancelación con reprogramación, a todas cancelaciones adicionales aplicara la tarifa de cancelación.</span> <br><br>
                <span>Tenga en cuenta que Go guau como plataforma de intermediación y los paseadores como ejecutores de la actividad de paseo no realizan cancelaciones de servicios, esta es una solicitud inherente al cliente y deberá hacerse única y exclusivamente a través de la línea 3194115792 y no al PROVEEDOR.</span> <br><br>
                <span>Usted como cliente reconoce y acepta que por lluvia los servicios que se encuentran en ejecución pueden llegar a no completarse, en distancia mínima, seguimiento u otro aspecto, por lo que al tratarse de un factor de fuerza mayor no se hará responsable ni al proveedor ni a Go Guau.</span> 
                <span>En ninguno de los casos anteriores procede devolución de dinero.</span> 
                
            </p>
        </div>

        <div>
            <p class="subtitle2">6. RESPONSABILIDAD SOCIAL</p>
            <p class="paragraph">En ánimo de aportar socialmente, GO GUAU facilitará sin obligarse a ello, a que los proveedores de servicios independientes (PROVEEDORES), tengan el perfil de estudiantes activos, para los cuales los ingresos que puedan resultar de su labor ante los CLIENTES representen una oportunidad para mantener sus actividades académicas o sustente sus necesidades básicas.</p>
        </div>
        
    </div>
    <br>
    <div>
        <p class="subtitle">III. TERMINOS Y CONDICIONES COMPLEMENTARIOS. </p>
        <br>
        <div>
            <p class="subtitle2">1. CONDICIONES SUPLEMENTARIAS</p>
            <p class="paragraph">Usted acuerda que GO GUAU podrá aplicar condiciones suplementarias a los Servicios, como políticas para un evento, una actividad o una promoción particular, y que dichas condiciones suplementarias se le comunicarán mediante la plataforma. Las condiciones suplementarias se establecen como adicionales a estas Condiciones, y se considerarán una parte de las mismas. Las condiciones suplementarias prevalecerán sobre estas Condiciones aquí estipuladas en el caso de conflicto respecto a los Servicios a que apliquen.</p>
            <p class="paragraph">GO GUAU podrá modificar las Condiciones relativas a los Servicios o productos cuando lo considere oportuno. Las modificaciones serán efectivas después de la publicación de las mismas en la siguiente ubicación de internet: 
                <a href="https://goguau.co/pages/terms">goguau.co/pages/terms</a>  . 
                Su acceso o uso de los Servicios o productos después de dicha publicación constituye su consentimiento a vincularse por las Condiciones ahí estipuladas y sus modificaciones.</p>
        </div>

        <div>
            <p class="subtitle2">2. TERMINOS Y CONDICIONES ADICIONALES A LOS SERVICIOS</p>
            <p class="paragraph">A no ser que GO GUAU lo acepte mediante un contrato por aparte y por escrito con usted, los Servicios se ponen a disposición solo para uso personal, no comercial. Usted 
                <strong> reconoce que GO GUAU no presta servicios de paseos y adiestramiento, ni venta de productos y que estos son prestados por terceros proveedores particulares independientes, que no están empleados por GO GUAU ni por ninguna de sus filiales o representantes, también reconoce y acepta que Go GUAU es una empresa de tecnología, que facilita la comunicación entre CLIENTES Y PROVEEDORES de servicios y productos de mascotas, sin obligarse a ello.</strong></p>

            <p class="paragraph padded">
                <span class="bullet">●</span><span class="margin"><strong>Licencia:</strong> </span><br><br>
                <span>Sujeto al cumplimiento de estas Condiciones, GO GUAU le otorga una licencia limitada, no exclusiva, no sublicenciable, revocable, no transferible para: (i) el acceso y uso de la PLATAFORMA, mediante su dispositivo personal u ordenador u otro; y (ii) el acceso y uso de cualquier contenido, información y material relacionado que pueda ponerse a disposición, en cada caso solo para su uso personal, no comercial. GO GUAU y sus licenciantes se reservan cualquier derecho que no haya sido expresamente otorgado por el presente documento.</span><br><br>

                <span class="bullet">●</span><span class="margin"><strong>Restricciones:</strong> </span><br><br>
                <span>Usted no podrá: (i) retirar cualquier nota de derechos de autor, marca registrada u otra nota de propiedad de cualquier contenido de GO GUAU; (ii) reproducir, modificar, preparar obras derivadas sobre los Servicios, distribuir, licenciar, arrendar, revender, transferir, exhibir, presentar públicamente, transmitir, retransmitir o explotar de otra forma contenido, servicios y/o herramientas de GO GUAU , excepto como se permita expresamente por GO GUAU; (iii) descompilar, realizar ingeniería inversa o desmontar LA PLATAFORMA, excepto como se permita por la ley aplicable; (iv) enlazar, reflejar o enmarcar cualquier parte de los Servicios; (v) causar o lanzar cualquier programa o script con el objeto de extraer, indexar, analizar o de otro modo realizar prospección de datos de cualquier parte de LA PLATAFORMA o sobrecargar o bloquear indebidamente la operación y/o funcionalidad de cualquier aspecto; o (vi) intentar obtener un acceso no autorizado o dañar cualquier parte de los Servicios o sus sistemas o redes.</span><br><br>
                
                <span class="bullet">●</span><span class="margin"><strong>Prestación de los Servicios:</strong> </span><br><br>
                <span>Usted reconoce y acepta que algunos Servicios o productos podrán ponerse a su disposición bajo varias marcas u opciones de pedidos asociadas como: (i) ciertas subsidiarias o afiliados de GO GUAU; o (ii) Terceros proveedores independientes, incluidos paseadores caninos, adiestradores, veterinarias y otros.</span><br><br>
               
                <span class="bullet">●</span><span class="margin"><strong>Servicios y contenido de Terceros:</strong> </span><br><br>
                <span>Los Servicios a su disposición podrán incluir o promocionar servicios y contenido de terceros que GO GUAU no controla (incluida la publicidad), por ello no los respalda y en ningún caso será responsable de cualquier producto o servicio de dichos terceros proveedores.</span><br><br>
               
                <span class="bullet">●</span><span class="margin"><strong>Titularidad:</strong> </span><br><br>
                <span>Los Servicios de uso de la plataforma y todos los derechos relativos a estos son y permanecerán como propiedad de GO GUAU o de sus licenciantes. Ninguna de estas Condiciones ni el uso de los Servicios le transfieren u otorgan ningún derecho: (i) sobre o en relación con los Servicios o productos, excepto en cuanto a la licencia limitada otorgada anteriormente; o bien (ii) a utilizar o mencionar en cualquier modo el nombre de empresa, logotipos, nombres de productos y servicios, marcas comerciales o marcas de servicio de GO GUAU o de sus licenciantes.</span><br><br>
            </p> 
        </div>

        <div>
            <p class="subtitle2">3. USO DE LOS SERVICIOS</p>
            <p class="paragraph padded">
                <span class="bullet">●</span><span class="margin"><strong>Cuentas de usuario:</strong> </span><br><br>
                <span>Con el fin de usar los Servicios, usted debe registrarse en <a href="www.goguau.co">goguau.co</a> y mantener activa una cuenta personal de usuario (“Cuenta”). Para obtener una debe tener como mínimo 18 años. El registro de la cuenta requiere que comunique a GO GUAU determinada información personal, como su nombre, dirección, número de teléfono móvil, número de identificación así como por lo menos un método de pago válido. Usted se compromete a mantener la información de su Cuenta precisa, completa y actualizada, de no hacerlo o incluso el hecho de tener un método de pago inválido o que haya vencido, podrá resultar en su imposibilidad para acceder y utilizar la PLATAFORMA. Usted es responsable de toda la actividad que ocurra en su Cuenta y se compromete a mantener en todo momento de forma segura y secreta el nombre de usuario y la contraseña. A menos que GO GUAU permita otra cosa por escrito, usted solo puede poseer una Cuenta.</span><br><br>
                
                <span class="bullet">●</span><span class="margin"><strong>Consentimiento para llamadas mensajes de texto, correos y otras comunicaciones:</strong> </span><br><br>
                <span>Al solicitar los servicios o productos o al usar la PLATAFORMA de GO GUAU, Usted da su consentimiento expreso para recibir y aceptar comunicaciones nuestras, o de nuestros Afiliados (como se define a continuación) y sus respectivos representantes, por correo electrónico, llamadas telefónicas, mensajes de texto (incluso mediante un sistema de marcación telefónica automática o una voz pregrabada), notificaciones push u otros medios, en cualquiera de las direcciones de correo electrónico y / o números de teléfono proporcionados por usted o en su nombre a GO GUAU. Usted acepta que las comunicaciones autorizadas anteriores pueden iniciarse para cualquier transacción, servicio al cliente, publicidad, marketing, promoción, cobro de deudas, administración de cuentas u otros fines. Usted podrá solicitar la no recepción de mensajes PUBLICITARIOS O PROMOCIONALES por parte de GO GUAU en cualquier momento, para ello deberá enviar un correo electrónico a contacto@goguau.co indicando que no desea recibir más dichos mensajes, incluirá ahí el número de teléfono del dispositivo móvil a intervenir. Usted reconoce que solicitar la no recepción de mensajes podrá afectar al uso que usted haga de los Servicios.</span><br><br>
                
                <span class="bullet">●</span><span class="margin"><strong>Códigos promocionales:</strong> </span><br><br>
                <span>GO GUAU podrá, a su sola discreción, crear códigos promocionales que podrán ser canjeados por créditos de Cuenta u otros elementos o beneficios relacionados con el uso de la plataforma y/o los servicios de un Tercero, con sujeción a cualquier condición adicional que GO GUAU establezca sobre la base de cada código promocional (“Códigos promocionales”). Usted acuerda que los Códigos
                    promocionales: (i) deben usarse para el propósito deseado, y de manera lícita; (ii) no podrán duplicarse, venderse o transferirse de ninguna manera, o ponerse a disposición del público general a menos que sea con el permiso de GO GUAU; (iii) podrán ser invalidados por GO GUAU en cualquier momento por cualquier motivo sin responsabilidad para GO GUAU; (iv) podrán usarse solo conforme a las condiciones específicas que GO GUAU establezca para dicho Código promocional; (v) no son válidos como efectivo; y (vi) podrán caducar antes de que usted los utilice. GO GUAU se reserva el derecho de retener o deducir el crédito u otros elementos o beneficios obtenidos a través de la utilización de los Códigos promocionales, en el caso de que determine o crea que el uso o el canje de los Códigos promocionales fue de modo erróneo, fraudulento, ilegal o infringiendo las condiciones del Código promocional o las presentes Condiciones.</span><br><br>
                
                <span class="bullet">●</span><span class="margin"><strong>Contenido proporcionado por el Usuario:</strong> </span><br><br>
                <span>GO GUAU podrá, a su sola discreción cuando considere oportuno, permitirle que envíe, cargue, o publique, información de texto, audio y/o visual, incluidos comentarios y opiniones relativos a los Servicios, así mismo usted autoriza que EL INTERMEDIARIO Y EL PROVEEDOR obtengan material fotográfico y fílmico de su mascota, todo el contenido anteriormente estipulado se consolida como CONTENIDO DE USUARIO, acepta usted entonces que todo Contenido de usuario facilitado o autorizado por usted seguirá siendo de su propiedad. No obstante, al proporcionar o autorizar dicho Contenido a GO GUAU, usted otorga una licencia mundial, perpetua, irrevocable, transferible, libre de regalías, con derecho a sublicenciar, usar, copiar, modificar, crear obras derivadas, distribuir, exhibir públicamente, presentar públicamente o de otro modo explotar de cualquier manera dicho Contenido de usuario en todos los formatos y canales de distribución, conocidos ahora o ideados en un futuro (incluidos en relación con los Servicios y el negocio de GO GUAU y en sitios y servicios de terceros), sin aviso o consentimiento suyo y sin requerirse el pago a usted o a cualquier otra persona o entidad. Usted acepta el uso de sus datos de contacto para ser etiquetado en todas las redes sociales. En caso de no autorizar el uso de la anterior información debe informarlo por escrito a <strong>contacto@goguau.co</strong>  antes de la solicitud de servicios o productos.</span><br><br>
                <span>Usted declara y garantiza que: (i) es el único y exclusivo propietario de todo el Contenido de usuario facilitado o autorizado y que tiene todos los derechos, licencias, consentimientos y permisos necesarios para otorgar a GO GUAU la licencia del Contenido como se ha establecido anteriormente; y (ii) ni el Contenido de usuario, ni su presentación, carga, publicación o puesta a disposición, ni el uso por parte de GO GUAU del Contenido de usuario como está aquí permitido, infringirán, malversarán o violarán la propiedad intelectual o los derechos de propiedad de un tercero o resultarán en la violación de cualquier ley o reglamento aplicable.</span><br><br>
                <span>Usted acuerda no proporcionar Contenido de usuario que sea difamatorio, calumnioso, odioso, violento, obsceno, pornográfico, ilícito o de otro modo ofensivo; GO GUAU, a su sola discreción y en cualquier momento y por cualquier motivo, sin avisarle previamente, podrá revisar, controlar o eliminar Contenido de usuario.</span><br><br>
                
                
                <span class="bullet">●</span><span class="margin"><strong>Acceso a la red y dispositivos:</strong> </span><br><br>
                <span>Usted es responsable de obtener el acceso a la red de datos necesario para utilizar los Servicios. Podrán aplicarse las tarifas y tasas de datos y mensajes de su red móvil si usted accede o utiliza los Servicios desde un dispositivo inalámbrico y usted será responsable de dichas tarifas y tasas. Usted
                    es responsable de adquirir y actualizar el hardware compatible o los dispositivos necesarios para acceder y utilizar los Servicios y Aplicaciones y cualquier actualización de estos. GO GUAU no garantiza que los Servicios, o cualquier parte de estos, funcionen en cualquier hardware o dispositivo particular. Además, los Servicios podrán ser objeto de disfunciones o retrasos inherentes al uso de Internet y de las comunicaciones electrónicas.</span><br><br>                                
                
            </p>
        </div>

        <div>
            <p class="subtitle2">4. PAGO</p>
            <p class="paragraph">Usted entiende que el uso de los Servicios de la PLATAFORMA de GO GUAU derivan en cargos (“Cargos”) a favor de un Tercero prestador de los servicios que hemos resuelto llamar PROVEEDOR, en ese orden de ideas antes de recibir los servicios o los bienes que usted demanda mediante el uso de la plataforma, GO GUAU facilitará su pago en nombre del PROVEEDOR como agente de cobro limitado. El pago de los Cargos bajo esta modalidad se considerará como el pago efectuado directamente por usted como CLIENTE al PROVEEDOR. Dichos Cargos pueden incluir una combinación de lo siguiente: (1) tarifa de cancelación, (2) tarifa de servicio, reserva o uso de la plataforma, (3) tarifa de confianza y seguridad, (4) recargos, (5) tarifa de conveniencia (6) impuestos (7) tarifa de desplazamiento, (8) tarifa de exclusividad,  (9) tarifa de recaudo y  (10) Otros cargos. Usted podrá solicitar una descripción de los Cargos Antes del pago. Tiene absoluta discreción para solicitar o no solicitar servicios de un PROVEEDOR si la tarifa o cargo total aplicable no es aceptable para usted; dichos pagos son definitivos y no reembolsables, a menos que GO GUAU determine lo contrario.</p>
            <p class="paragraph">Todos los pagos son anticipados, las excepciones serán individuales y manifiestas por GOGUAU, en dichos casos el pago será al momento de prestación del servicio y tendrá un recargo adicional de 400 pesos reconocido como Tarifa de Recaudo, NO SE PRESTAN SERVICIOS NI PRODUCTOS QUE NO TENGAN UN PAGO ASOCIADO;   GO GUAU podrá enviarle un recibo por correo electrónico el cual no constituye factura, dado que GO GUAU no es el prestador del servicio. Puede pagar usted directamente o pedir la gestión de pago automático, Si se determina que el método de pago de su Cuenta principal ha caducado, es inválido o de otro modo no sirve para cobrarle, usted acepta que GO GUAU, como agente de cobro limitado del PROVEEDOR del servicio, utilice un método de pago secundario registrado en su Cuenta, si estuviera disponible.</p>
            <p class="paragraph">GO GUAU, en cualquier momento y a su sola discreción, se reserva el derecho de establecer, eliminar y/o revisar los Cargos para alguno o todos los servicios o bienes obtenidos a través del uso de la plataforma o las dependencias de GO GUAU. Además, usted reconoce y acepta que los Cargos aplicables en determinadas zonas geográficas podrán incrementar sustancialmente durante los periodos de alta demanda. GO GUAU usará los esfuerzos razonables para informarle de los Cargos que podrán aplicarse y usted acepta pagarlos. GO GUAU podrá, cuando lo considere oportuno, proporcionar a determinados usuarios ofertas promocionales y descuentos que pueden resultar en el cobro de diferentes importes por estos o similares servicios o bienes obtenidos a través del uso de la plataforma.</p>
            <p class="paragraph"></p>
            <p class="paragraph">Usted entiende y acepta que es libre de proporcionar un pago adicional (Propina) como gratificación a cualquier PROVEEDOR que le proporcione servicios o bienes obtenidos mediante la plataforma.</p>
            <p class="paragraph">Después de que haya recibido los bienes o servicios obtenidos a través dela plataforma, tendrá la oportunidad de calificar su experiencia y dejar comentarios adicionales.</p>
        </div>

        <div>
            <p class="subtitle2">5. RENUNCIAS, LIMITACIÓN DE RESPONSABILIDAD E INDEMNIDAD DE GO GUAU</p>

            <p class="paragraph padded">
                <span class="bullet">●</span><span class="margin"><strong>Renuncia:</strong> </span><br><br>
                <span>Los servicios se proporcionan “tal cual” y “como disponibles”. GO GUAU renuncia a toda declaración y garantía, expresa, implícita o estatutaria, no expresamente establecida en estas condiciones, incluidas las garantías implícitas de comerciabilidad, idoneidad para un fin particular y no violatorio. además, GO GUAU no hace declaración ni presta garantía alguna relativa a la fiabilidad, puntualidad, calidad, idoneidad o disponibilidad de los servicios o bienes solicitados a través del uso de la plataforma, o que los servicios no serán interrumpidos o estarán libres de errores. GO GUAU realiza un filtro de proveedores procurando el personal idóneo, así mismo realiza una prueba de conocimientos básicos para la prestación del servicio luego de una documentación previa, no obstante, no garantiza la calidad, idoneidad, seguridad o habilidad dado que son  TERCEROS PROVEEDORES del servicio y no empleados de la marca. Usted acuerda que todo riesgo derivado de su uso de los servicios o bien solicitado en relación con aquellos será únicamente suyo, en la máxima medida permitida por la ley aplicable.</span><br><br>   
               
                <span class="bullet">●</span><span class="margin"><strong>Limitación de responsabilidad:</strong> </span><br><br>
                <span>GO GUAU no será responsable de daños indirectos, incidentales, especiales, ejemplares, punitivos o emergentes, incluidos el lucro cesante, la pérdida de datos, la lesión personal o el daño a la propiedad, ni de perjuicios relativos, o en relación con, o de otro modo derivados de cualquier uso de los servicios, incluso aunque GO GUAU haya sido advertido de la posibilidad de dichos daños. GO GUAU no será responsable de cualquier daño, responsabilidad o pérdida que deriven de: (i) su uso o dependencia de los servicios o su incapacidad para acceder o utilizar los servicios; o (ii) cualquier transacción o relación entre usted y cualquier tercero PROVEEDOR del servicio, aunque GO GUAU hubiera sido advertido de la posibilidad de dichos daños. GO GUAU no será responsable del retraso o de la falta de ejecución resultante que vayan más allá del control razonable de GO GUAU. En ningún caso la responsabilidad total de GO GUAU hacia usted en relación con los servicios y por todos los daños que se puedan causar, las pérdidas y los juicios podrá exceder de quinientos mil pesos ($500.000 COP).</span><br><br>   
                <span>Usted podrá utilizar la plataforma de GO GUAU y/o sus dependencias para solicitar y planificar servicios de PASEADORES Y ADIESTRAMIENTO CANINO, O BIENES, con terceros PROVEEDORES, por ello acepta que GO GUAU no tiene responsabilidad alguna hacia usted en relación a dichos servicios y que las obligaciones de su objeto se han establecido expresamente en estas condiciones.</span><br><br>   
                
                <span class="bullet">●</span><span class="margin"><strong>Indemnidad:</strong> </span><br><br>
                <span>Usted acuerda mantener indemne a GO GUAU y manifiesta responder frente al mismo, sus consejeros, directores, empleados y agentes por cualquier reclamación, demanda, pérdida, responsabilidad y gasto (incluidos los honorarios de abogados) que deriven de: (i) su uso de la plataforma y/o dependencias, servicios o bienes obtenidos a través de  la misma; (ii) su incumplimiento o violación de cualquiera de estas Condiciones; (iii) el uso por parte de GO GUAU de su Contenido de usuario; o (iv) su infracción de los derechos de cualquier tercero, incluidos PROVEEDORES del servicio.</span><br><br>   
                
                <span class="bullet">●</span><span class="margin"><strong>Responsabilidad de CLIENTES:</strong> </span><br><br>
                <span>Como CLIENTE y dueño de la mascota, usted la representa, hace un convenio y garantiza a GO GUAU y a cada PROVEEDOR que: (1) las mascotas para las que solicita servicios son suyas; (2) sus mascotas están libres de pulgas, garrapatas y otras afectaciones; (3) ha divulgado cualquier información material sobre sus mascotas, incluidas afecciones médicas preexistentes y otros problemas de comportamiento que pueden ser relevantes o afectar la capacidad de un PROVEEDOR para brindar los Servicios; (4) usted tendrá a sus mascotas completamente vacunadas y al día con todas las formas de medicina preventiva antes de recibir los servicios de un PROVEEDOR, se asegurará de que las garras de las mascotas estén cortas para que no se haga daño o haga daño a otros (tenga en cuenta que las garras largas en mascotas muy dinámicas representan lesiones por forzamiento con el pavimento, lo que no depende de la voluntad o actuar del proveedor); (5) cumplirá con todas las leyes y ordenanzas aplicables; y (6) la (s) mascota (s) para las que busca servicios no son peligrosas ni pueden dañar a otras personas, en caso de duda o si la mascota pertenece a una raza  potencialmente peligrosa le pondrá bozal.</span><br><br>   
                <span>Usted reconoce y acepta que si permite que otra persona acceda a su cuenta o si solicita un servicio para una mascota que no es suya, en violación de estos Términos, usted y esa otra persona serán solidariamente responsable de todos los actos y omisiones en relación con la contratación de los Servicios, incluidos todos los costos incurridos o asociados con dicha contratación.</span><br><br>   
                <span>Usted es completamente responsable de las acciones de su (s) mascota (s). Usted comprende, reconoce y acepta que, entre usted Y GO GUAU, usted será responsable de todos y cada uno de los reclamos, costos, procedimientos, demandas, pérdidas, daños y gastos (incluidos, entre otros, los honorarios razonables de abogados y los costos legales  de cualquier tipo o naturaleza, que surja o se relacione con el comportamiento de su (s) mascota (s), incluidas, entre otras, las reclamaciones de terceros (incluidos los PROVEEDORES) por daños, pérdidas o lesiones resultantes de mordeduras o ataques a tales terceros por su (s) mascota (s). Los incidentes, lesiones, daños o pérdidas causados ​​por su mascota pueden regirse por las leyes estatales de responsabilidad aplicables. GO GUAU cooperará con las autoridades policiales apropiadas para proporcionar la información solicitada con respecto a la situación que llegará a presentarse. Si elige utilizar un servicio de PROVEEDOR, deberá contar con un seguro de responsabilidad aplicable Y suficiente para los servicios de mascotas que solicite.
                    <strong>Con base en lo anterior, usted como CLIENTE acepta y se hace responsable de tomar todas las medidas de seguridad posibles y de brindar al PROVEEDOR las indicaciones necesarias en pro de la seguridad, GO GUAU le recomienda el uso permanente de bozal, el NO uso de correas retráctiles o de ahogamiento, el uso de arnés, usted acepta que cualquier evasión a las anteriores recomendaciones es su responsabilidad, así como los perjuicios que pueda ello conllevar.</strong> </span><br><br>   
                <span>Usted comprende y acepta que cualquier PROVEEDOR de quien pueda buscar u obtener servicios de mascotas a través de la plataforma es un contratista independiente y no un socio, empresa conjunta, agente, representante legal, empleador, trabajador o empleado de GO GUAU. Los PROVEEDORES no tienen la capacidad ni la autoridad para representar a GO GUAU o hacer declaraciones o compromisos en su nombre.</span><br><br>   
                <span>Los PROVEEDORES tienen la única y absoluta discreción de rechazar cualquier servicio de cuidado de mascotas que usted pueda solicitar.</span><br><br>   
                <span>En el improbable caso de que su mascota necesite atención médica o veterinaria inmediata en una situación de emergencia, usted autoriza a su PROVEEDOR y a GO GUAU para la provisión de atención veterinaria, así mismo usted será el único responsable de los costos de dicho tratamiento médico y autoriza a GO GUAU para cobrar dichos costos de cualquiera de sus métodos de pago.</span><br><br>   
                <span>Como dueño de una mascota (CLIENTE), usted reconoce que GO GUAU está en el negocio de conectar dueños de mascotas (CLIENTES) y PROVEEDORES de servicios para las mismas, y ese negocio es a través del cual GO GUAU obtiene sus ingresos. Por ello, en caso de que usted como CLIENTE mediante la plataforma de GO GUAU vaya a utilizar servicios de un PROVEEDOR que conoció previamente por fuera de la actividad de esta empresa, deberá manifestarlo mediante correo electrónico a la dirección contacto@goguau.co y abstenerse de tomar el servicio, para reasignarle un nuevo proveedor ; en caso de que no realice dicha comunicación, usted como CLIENTE acepta que ha conocido por primera vez al PROVEEDOR gracias al servicio de intermediación de GO GUAU, con base en ello, si usted pactara algún servicio para mascotas con alguno de los PROVEEDORES que se le facilitó, y dicho pacto se hiciera por fuera de la PLATAFORMA o sin autorización de GO GUAU, GO GUAU tendrá el derecho a cobrar tanto al propietario de la mascota (CLIENTE) como al PROVEEDOR una tarifa de referenciación, Esta tarifa de referenciación se cobrará una vez por cada relación de servicios específica dada entre el CLIENTE y el PROVEEDOR, La tarifa de referenciación será de $ 2.000.000 (Dos Millones de Pesos).</span><br><br>                                 
            </p>                                                         
        </div>

        <div>
            <p class="subtitle2">6. LEGISLACIÓN APLICABLE, ARBITRAJE</p>
            <p class="paragraph">Salvo que aquí se especifique lo contrario, las presentes Condiciones se regirán e interpretarán exclusivamente en virtud de la legislación Colombiana. Cualquier disputa, conflicto, reclamación o controversia, del tipo que sea, que resulte de las presentes Condiciones o que se relacione en gran parte con ellas, incluyendo las relativas a su validez, interpretación y exigibilidad (cualquier “Disputa”), deberán someterse forzosamente a procedimientos de mediación en virtud del Reglamento de Mediación de la Cámara de Comercio del Cauca. Si dicha disputa no fuese solucionada en un plazo de sesenta (60) días desde la fecha en la que se formalice la solicitud de mediación en virtud del Reglamento de Mediación de la CCC, se solucionará exclusiva y definitivamente mediante arbitraje en virtud del Reglamento de la Cámara de Comercio del Cauca. Las disposiciones sobre Proceso Expedito y del Árbitro de Emergencia del Reglamento de la CCI no se aplicarán. La disputa será resuelta por un (1) árbitro nombrado para tal fin en virtud del Reglamento de la CCC. El lugar tanto para la mediación como para el arbitraje será Popayán, Colombia. La existencia y el contenido de los procedimientos de mediación y arbitraje, incluidos los documentos e informes presentados por las partes, la correspondencia de la CCC, la correspondencia del mediador, los pedidos y los laudos emitidos por el único árbitro deberán permanecer en estricta confidencialidad y no deberán ser revelados a ningún tercero sin el consentimiento expreso por escrito de la otra parte.</p>
        </div>

        <div>
            <p class="subtitle2">7. TRATAMIENTO DE DATOS PERSONALES</p>
            <p class="paragraph">Al aceptar los presentes términos, el cliente autoriza a GO GUAU el tratamiento de sus datos personales, de igual forma GO GUAU Se compromete a no revelar la información que se digita o transfiere a nuestra empresa, de acuerdo con las normas de la Ley 527 que reglamenta el Comercio Electrónico en Colombia y la Ley 1581 de 2012 sobre el uso de datos confidenciales. Con la presente Política de Tratamiento y Protección de Datos Personales.</p>
            <p class="paragraph">El responsable del tratamiento de los datos personales es el sr. Jose Luis Cerón, con domicilio en Popayán. El e-mail dispuesto para ese fin es: contacto@goguau.co</p>

            <p class="paragraph padded">
                <span class="bullet">●</span><span class="margin"><strong><strong>Tratamiento al cual serán sometidos los datos:</strong> </strong> </span><br><br>
                <span>Los datos y autorizaciones de tratamiento se recolectarán por medios electrónicos a través de la
                        página <a href="https://goguau.co">goguau.co</a> </span><br><br>   
                
                <span class="bullet">●</span><span class="margin"><strong><strong>Permisos de la aplicación web:</strong> </strong> </span><br><br>
                <span>En esta aplicación puede pedir algunos permisos que le permitan desarrollar acciones para crear
                    solicitudes de servicio, y productos. Los permisos solicitados serán los siguientes:</span><br><br>   
                
                <span class="bullet">●</span><span class="margin"><strong><strong>Información básica:</strong> </strong> </span><br><br>
                <span>Por defecto, esto incluye ciertos datos del usuario, tales como identificador, nombre, foto, correo
                    electrónico y su localidad.</span><br><br>   
                <span>Los datos personales pueden proporcionarse libremente por el usuario, o automáticamente cuando haga uso de la aplicación.</span><br><br>   
                <span>Los usuarios son responsables de todos los datos personales obtenidos de terceros, publicados o compartidos a través de esta aplicación y confirmar que tienen el consentimiento del tercero para proporcionar los datos.</span><br><br>   
                <span>Los datos recaudados, así como las autorizaciones de los usuarios registrados en la aplicación goguau.co, deberán contener la autorización expresa de dichos usuarios para que goguau.co pueda utilizarlos. Los registros y las autorizaciones serán almacenados en las bases de datos de goguau.co y permanecerán bajo su custodia en condiciones de idoneidad, confidencialidad y seguridad generalmente admitidas. Sólo el personal autorizado podrá acceder a estas bases de datos. Se observarán los protocolos de acceso y seguridad que se consideran estándar en estas actividades para evitar vulneración o manipulación de la información recopilada.</span><br><br>   
                <span>No obstante lo anterior, goguau.co podrá operar las bases de datos mediante un encargado del tratamiento de datos, en cuyo caso, hará saber a los titulares de la información que estas políticas se extenderán y, por ello, serán aplicables a tal encargado, de forma que el titular pueda ejercer los derechos que le confiere la ley, tanto frente a goguau.co como frente al encargado designado por ésta. La información recopilada se usará en la forma descrita enseguida.</span><br><br>   

                <span class="bullet">●</span><span class="margin"><strong><strong>Actualizaciones de los términos:</strong> </strong> </span><br><br>
                <span>Go Guau puede actualizar los presentes términos para reflejar las modificaciones que se generan en los servicios. Por lo tanto tanto, debe revisar estas páginas periódicamente, si renovamos de forma sustancial estos términos, le notificaremos con debida antelación. El uso continuado de los servicios después de dichos cambios constituye su aceptación.</span><br><br>  
                
                <span class="bullet">●</span><span class="margin"><strong><strong>Uso (finalidad) de la información personal recopilada en las bases de datos:</strong> </strong> </span><br><br>
                <span>La información personal que ha recopilado goguau.co desde el inicio de sus operaciones en el año
                    2020, y la que recopile en adelante, se usará para los siguientes fines:</span><br><br>   
                <span>Envío de información y documentos relacionados con los servicios que debe prestar la administración de goguau.co, esto es, la que deba remitirse a los usuarios en general hagan uso de sus servicios o productos.</span><br><br>   
                <span>Remisión de información, requerimientos y notificaciones a todos los empleados, proveedores, contratistas y colaboradores de goguau.co, que se encuentran registrados en nuestras bases de datos.</span><br><br>   
                <span>Envío de correos electrónico y/o demás mensajes de datos.</span><br><br>  
                
                <span>Remisión de ofertas, incentivos y promociones de los usuarios de goguau.co.</span><br><br>  
                <span>Análisis y segmentación de la información para elaborar estudios y estadísticas sobre preferencias de consumo.</span><br><br>  
                <span>Dar cumplimiento a exigencias legales y requerimientos de autoridades judiciales.</span><br><br>  
                <span><strong>Derechos que le asisten al titular de la información</strong> </span><br><br>  
                
                <span>De acuerdo con las normas constitucionales y legales, el titular de la información recopilada en nuestra base de datos cuenta con las siguientes prerrogativas y derechos, los cuales puede invocar o ejercer frente a goguau.co o frente al encargado del tratamiento de datos personal que obra por cuenta de goguau.co como responsable:</span><br><br>  
                <span>Acceder de manera gratuita a sus datos personales, así como conocerlos, rectificarlos, corregirlos y actualizarlos, siguiendo los procedimientos establecimientos más adelante.</span><br><br>  
                <span>Solicitar y obtener prueba de la autorización concedida para el tratamiento de sus datos personales, excepto en el caso en que ella se presuma por haberse usado mecanismos, como lo prevé en el artículo 10 del decreto 1377 de 2013.</span><br><br>  
                <span>Obtener información sobre el uso que se ha dado a su información personal.</span><br><br>  
                
                <span>Acudir ante las autoridades, especialmente ante la Superintendencia Delegada para la Protección de Datos Personales de las SIC, con el fin de solicitar y exigir al amparo de los derechos que le confieren las leyes.</span><br><br>  
                <span>Renovar, en cualquier momento, a autorización para el tratamiento de sus datos personales, modificarla o condicionarla. Así mismo, solicitar la supresión del dato, su modificación o aclaración, salvo que sea necesaria la información por razones legales o contractuales.</span><br><br>  

                
                <span class="bullet">●</span><span class="margin"><strong><strong>Procedimientos para que los titulares de la información que reposa en nuestra bases de datos, y la que se recopile en adelante:</strong> </strong> </span><br><br>
                <span>goguau.co establece los siguientes procedimientos:</span><br><br>   
                <span>1. El titular de la información (entendiéndose por él a sus causahabientes y apoderados) podrá solicitar en cualquier tiempo, dentro de los horarios de atención al público o de forma electrónica, información (consultas) sobre datos personales que se registran las bases de datos de Go Guau y sobre las autorizaciones concedidas.</span><br><br>   
                <span>2. Así mismo, podrá elevar peticiones o reclamaciones sobre aclaración, corrección, modificación, rectificación o supresión de datos; revocación o condicionamiento de autoridades para el tratamiento, acompañando los documentos o pruebas que pretendan hacer valer.</span><br><br>   
                <span>3. Para ejercer las prerrogativas a que se refieren los numerales anteriores, el titular deberá radicar petición escrita en las oficinas de administración Go Guau dirigida al ÁREA DE ADMINISTRACIÓN, identificándose plenamente, a fin de que Go Guau pueda corroborar que el peticionario es el titular de la información. En solicitud se debe precisar: a) Nombre completo y correcto del titular y/o su apoderado, si es el caso. b).Identificación del titular y de su representante, en el evento en que actúe a través de su apoderado. c) Dato o autorización que se requiere conocer, corregir, modificar, suprimir o revocar, con indicación clara y detallada de la forma en que se pide hacer la corrección o modificación. d) Domicilio o lugar donde recibirán respuesta.</span><br><br>   

                <span>4. El titular también puede remitir un correo a la siguiente dirección electrónica: contacto@goguau.co, dirigido al ÁREA DE INFORMACIÓN, con la información señalada en el numeral anterior. En este caso, Go Guau enviará la respuesta correspondiente a la misma dirección de correo de la cual procede la petición, siempre y cuando coincida con la registrada en la base de datos. No obstante, si lo considera, y con el único fin de establecer la identidad plena del peticionario y titular de la información, Go Guau podrá requerir esa información para que se identifique, antes de preceder a responderle.</span><br><br>   
                <span>5. La respuesta a las consultas a que se refiere el numeral 1°, será enviada por Go Guau en un término máximo de diez (10) días hábiles contados a partir de la fecha de recibo de la misma.</span><br><br>   
                <span>6. Cuando no fuere posible atender la consulta dentro de dicho término, se informará al interesado, expresando los motivos de la demora y señalando la fecha que se atenderá su consulta, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer término.</span><br><br>   
                <span>7. La respuesta a las reclamaciones a que se refiere el numeral 2°, se dará por Go Guau en un término máximo de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, se informará al interesado los motivos de la demora y al fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ochos (8) días hábiles siguientes al vencimiento del primer término.</span><br><br>                                                    
            </p>
        </div>

        <div>
            <p class="subtitle2">8. OTRAS DISPOSICIONES</p>

            <p class="paragraph">Las reclamaciones por infracción de derechos de autor deberán enviarse por escritor al correo {{email}}</p>

            <p class="paragraph padded">
                <span class="bullet">●</span><span class="margin"><strong><strong>Notificaciones:</strong> </strong> </span><br><br>
                <span>GO GUAU podrá notificar por medio de una ubicación general en su plataforma, mediante un correo electrónico enviado a su dirección electrónica o por comunicación escrita enviada a su dirección de residencia, según lo dispuesto en su Cuenta de usuario.</span><br><br>  
                
                <span class="bullet">●</span><span class="margin"><strong><strong>Disposiciones generales:</strong> </strong> </span><br><br>
                <span>No podrá ceder ni transferir estas Condiciones, en todo o en parte, sin el consentimiento previo y escrito de GO GUAU. Usted da su aprobación a GO GUAU para ceder o transferir estas condiciones, en todo o en parte, incluido a: (i) una subsidiaria o un afiliado; (ii) un adquirente del capital, del negocio o de los activos de GO GUAU; o (iii) un sucesor por fusión.</span><br><br>  
                <span>Si cualquier disposición de estas Condiciones se considerara ilegal, nula o inexigible, ya sea en su totalidad o en parte, de conformidad con cualquier legislación, dicha disposición se considerará que no forma parte de estas Condiciones, aunque la legalidad, validez y exigibilidad del resto de las disposiciones no se verá afectada. En ese caso, las partes deberán reemplazar dicha disposición por una disposición legal, válida y exigible que tenga, en la medida de lo posible, un efecto similar al que tenía la disposición nula. Estas Condiciones constituyen el contrato íntegro y el entendimiento entre las partes en relación con el objeto y sustituye y reemplaza a todos los contratos o acuerdos anteriores o contemporáneos en relación con dicho objeto.</span><br><br> 
            </p>
        </div>
    </div>    
</div>
</template>

<script>
import termsData from '../../utils/PlansData'
export default {
    name: 'terms_and_conditions',
    data(){
        return{
            items: termsData.usersData,
            fields: [                
                { key: 'concept', label: ''},
                { key: 'hrs_min', label: 'Compra mínima en sesiones', },
                { key: 'hrs_max', label: 'Compra máxima en sesiones', },
                { key: 'cost1', label: 'Costo sesión 1 mascota', },
                { key: 'cost2', label: 'Costo sesión 2 mascotas', },
                { key: 'cost3', label: 'Costo sesión 3 mascotas', },
                { key: 'cost4', label: 'Costo sesión 4 mascotas', },
                { key: 'expiration', label: 'Caducidad en días', },
            ],
            fieldsReschedule: [
                { key: 'package', label: 'Paquete', },
                { key: 'quantity', label: 'Nro. de cancelaciones con reprogramación', },               
            ],
            itemsReschedule: termsData.rescheduleQuantity,
            itemsRainReschedule: termsData.rescheduleRainQuantity,
            itemsHotel: termsData.hotelData,
            fieldsHotel: [
                { key: 'concept', label: 'Cant. días'},
                { key: 'mascota_1', label: '1 mascota', },
                { key: 'mascota_2', label: '2 mascota', },
                { key: 'mascota_3', label: '3 mascota', },
                { key: 'mascota_4', label: '4 mascota', },
            ],
            activePage: 1,
            line: '319 411 5792 - 315 688 4840',
            email: 'contacto@goguau.co',

            itemsWalkingFeats: termsData.walkingFeats,
            fieldsWalkingFeats: [
               { key: 'concept', label: 'Concepto', },
               { key: 'description', label: 'Descripción', },  
            ],

            itemsTrainingFeats: termsData.trainingFeats,
            fieldsTrainingFeats: [
               { key: 'concept', label: 'Concepto', },
               { key: 'description', label: 'Descripción', },  
            ],
            itemsKindergartenFeats: termsData.kindergartenFeats,

            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            filterOn: [],
            dismissCountDown: 4,
            dismissSecs: 4,
            bankAccount: {
                number: '9122-9526-907',
                numDoc: '1.061.789.058'
            },
        }
    },
    watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields.filter(f => f.sortable).map(f => {
            return { text: f.label, value: f.key };
            });
        }
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;
    },
  },
  methods:{
    getBadge (concept) {
      switch (concept) {
        case 'Paseos grupales': return 'success'       
        case 'Paseos dedicado': return 'success'
        case 'Adiestramiento': return 'success'
        case 'Tarifas adicionales': return 'info'        
      
        default: 'primary'
      }
    },
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
    },        
  }
}
</script>

<style scoped>

	.title{
		padding: 2rem 0;
	}

	.subtitle{
		font: bold 20px 'Arial';
		color: #1155cc;
		line-height: 16px;
	}
	.subtitle2{
		font: bold 18px 'Arial';
		color: #1155cc;
		line-height: 14px;
	}

	.padded{
		padding-left: 68px;
	}

	.paragraph{
		text-align: justify;
		font: 20px;
		color: #666666;
		line-height: 19px;
	}

	.bullet{
		font: 13px 'Arial';
		color: #666666;
		line-height: 16px;
	}

	.number{
		font: 20px;
		color: #666666;
		line-height: 16px;
	}

	.margin{
		margin-left: 16px;
	}

</style>
